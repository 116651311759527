import { graphql } from 'gatsby';
import InterviewsPage from '../../views/interviews';

export default InterviewsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    interviews: allContentfulWywiady(sort: { order: DESC, fields: sortDate }) {
      edges {
        node {
          title
          slug
          type
          createdAt
          sortDate
          link
          description {
            description
          }
          thumbnail {
            title
            file {
              url
            }
          }
          node_locale
        }
      }
    }
  }
`;

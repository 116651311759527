import * as React from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout/layout';
import '../../styles/global.css';
import InterviewBox from './components/interviewBox';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import getCurrentTranslations from '../../components/contentful-translator';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_academy.jpg';
import { Page, PageContent, PageTitle } from '../../components/global/page';
import { Button } from '../../components/global/button';
import Breadcrumbs from '../../components/global/breadcrumbs';

const LoadMoreButton = styled(Button)`
  margin-top: 2rem;
  max-width: 10.75rem;
  align-self: center;
`;

function InterviewsPage({ data }) {
  const { language } = React.useContext(I18nextContext);
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = React.useState(1);
  const interviews = getCurrentTranslations(data.interviews.edges, language);

  const breadcrumbsPath = [
    { name: t('breadcrumbs.news'), path: `/news/` },
    { name: t('interviews.breadcrumbs.interviews'), path: '/news/interviews' }
  ];

  return (
    <Layout withHeader={false}>
      <Seo
        title={t('interviews.page-title')}
        description={t('interviews.page-description')}
        ogImage={ogImage}
      />
      <Page className="interviews">
        <PageContent>
          <Breadcrumbs path={breadcrumbsPath}></Breadcrumbs>
          <PageTitle>{t('interviews.title')}</PageTitle>
          {interviews.slice(0, 4 * currentPage).map((interview) => (
            <InterviewBox
              key={interview.node.title}
              watchMore={t('interviews.watch-more')}
              {...interview.node}></InterviewBox>
          ))}
          {interviews[currentPage * 4] && (
            <LoadMoreButton
              className="outlined"
              onClick={() => setCurrentPage(currentPage + 1)}>
              {t('interviews.load-more')}
            </LoadMoreButton>
          )}
        </PageContent>
      </Page>
    </Layout>
  );
}

export default InterviewsPage;

import styled from 'styled-components';
import { Row } from './utils';
import { Link } from 'gatsby';

export const ContentBox = styled(Row)`
  justify-content: space-between;
  max-width: 1180px;
  min-height: 352px;
  border: 1px solid #4ebfee;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 1rem;
  font-family: 'Raleway';
  font-weight: 600;
  line-height: 150%;
  background-color: white;
`;

export const ContentBoxTitle = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  font-family: 'Montserrat';
  font-size: 36px;
  line-height: 150%;
  color: #080e14;
  margin-top: 1rem;
  word-break: break-word;
`;

export const ContentBoxDescription = styled.div`
  margin: 1rem 0;
  font-weight: 600;
  color: #0f5575;
  max-width: 684px;
`;

export const ContentBoxImg = styled(Link)`
  object-fit: cover;
  border-radius: 10px;
  width: 500px;
  align-self: center;
  min-width: 500px;
  max-width: 500px;
  height: 286px;
  margin-left: 2rem;
  background-image: url(${(props) => props.src});
  background-size: contain;

  @media only screen and (max-width: 768px) {
    width: unset;
    min-width: unset;
    margin-left: unset;
  }

  @media only screen and (max-width: 500px) {
    max-width: 300px;
    height: 246px;
  }
`;

import styled from 'styled-components';
import { Column } from './utils';
import { respondTo } from '../../utils/breakpoints';

export const Page = styled.div`
  margin-top: 7rem;
  padding-top: 2rem;
  font-family: Raleway, sans-serif;
  ${respondTo.m`
    padding-top: 4rem;
  `}
  @media only screen and (max-width: 1200px) {
    margin: 7rem 1rem 0rem 1rem;
  }

  @media only screen and (max-width: 768px) {
    margin: 7rem 1rem 0rem 1rem;
  }
`;

export const PageContent = styled.div`
  margin: 0 auto;
  max-width: 1180px;
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  margin-bottom: 1rem;
  word-break: break-word;
`;

export const PageSubTitle = styled.div`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 36px;
  line-height: 150%;
  color: #080e14;
  margin-bottom: 3rem;
  span {
    color: #4ebfee;
  }
  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

export const FullWidthSection = styled(Column)`
  background: #0f5575;
  width: calc(100% - 10rem);
  color: white;
  padding: 5rem;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding: 5rem 2rem;
    width: calc(100% - 4rem);
  }
`;

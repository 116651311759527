import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { respondTo } from '../../utils/breakpoints';
import { Row, Icon, navigateTo } from './utils';

const AnimateHover = keyframes`
  from {
    opacity:0
  }
  to {
    opacity:1;
  }
`;

const Box = styled(Row)`
  font-family: 'Raleway';
  font-style: normal;
  height: 32px;
  font-weight: 600;
  background: #f5fafd;
  width: max-content;
  border-radius: 10px;
  color: #c4dfec;

  a {
    color: #c4dfec;
    text-decoration: none;
  }
  padding: 0 8px;
  margin-bottom: 2rem;
  @media only screen and (max-width: 768px) {
    padding: 0 0.25rem;
  }
  ${respondTo.xs`
    transform: scale(0.8) translate(-12%);
  `}
  ${respondTo.s`
    transform: scale(1) translate(0);
  `}
`;

const Step = styled(Link)`
  cursor: pointer;
  padding: 10px 1rem;
  border-bottom: inset 2px transparent;
  .home {
    margin-right: 10px;
    margin-left: 0;
  }
  &:last-child {
    color: #0f5575;
    &:hover {
      color: #0f5575;
      border-bottom: inset 2px #0f5575;
      animation: ${AnimateHover} 0.4s ease-in-out;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0 0.5rem;
  }
  &:hover {
    color: #87bcd3;
    border-bottom: inset 2px #87bcd3;
    animation: ${AnimateHover} 0.4s ease-in-out;
    i {
      color: #87bcd3 !important;
    }
  }
`;

const Breadcrumbs = (props) => {
  return (
    <Box className="align-center">
      <Step className="align-center" to="/">
        <FontAwesomeIcon icon={faHome} /> Start
      </Step>
      {props.path &&
        props.path.map((breadcrumb) => (
          <React.Fragment key={breadcrumb.name}>
            <span style={{ margin: '0 0.5rem' }}>&gt;</span>
            <Step to={breadcrumb.path}>{breadcrumb.name}</Step>
          </React.Fragment>
        ))}
    </Box>
  );
};

export default Breadcrumbs;

import React from 'react';
import styled from 'styled-components';
import {
  Row,
  Column,
  Icon,
  navigateTo,
  Tag
} from '../../../components/global/utils';
import {
  ContentBox,
  ContentBoxTitle,
  ContentBoxDescription,
  ContentBoxImg
} from '../../../components/global/contentbox';
import { Button, buttonCss } from '../../../components/global/button';
import { Link } from 'gatsby';

const WatchMoreButton = styled(Link)`
  ${buttonCss};
  margin-top: 2rem;
  width: fit-content;
  i {
    color: #0f5575 !important;
    margin-top: -4px;
  }
  @media only screen and (max-width: 700px) {
    margin-bottom: 2rem;
  }
`;

export default function InterviewBox(props) {
  return (
    <ContentBox shouldBreak={true}>
      <Column>
        <ContentBoxTitle to={props.slug}>{props.title}</ContentBoxTitle>
        <ContentBoxDescription>
          {props.description.description}
        </ContentBoxDescription>
        <Row shouldWrap={true}>
          {(props?.type?.split(', ') ?? [])?.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Row>
        <WatchMoreButton to={props.slug}>
          {props.watchMore}
        </WatchMoreButton>
      </Column>
      <ContentBoxImg
        src={props.thumbnail.file.url}
        alt={props.thumbnail.title}
        to={props.slug}
      />
    </ContentBox>
  );
}
